import { useEffect, useState } from "react"

interface Job {
    id: string
    attributes: {
        title: string
        "created-at": string
    }
    relationships: {
        department: {
            data: { id: string; type: string } | null
        }
        locations: {
            data: { id: string; type: string }[] // Multiple locations
        }
    }
}

interface Department {
    id: string
    attributes: {
        name: string
    }
}

interface Location {
    id: string
    attributes: {
        name: string
    }
}

interface ApiResponse {
    data: Job[]
    included: (Department | Location)[]
}

export function JobListings() {
    const [jobs, setJobs] = useState<Job[]>([])
    const [departments, setDepartments] = useState<Record<string, string>>({})
    const [locations, setLocations] = useState<Record<string, string>>({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function fetchJobs() {
            try {
                const response = await fetch(
                    "https://api.teamtailor.com/v1/jobs?include=department,locations",
                    {
                        method: "GET",
                        headers: {
                            Authorization:
                                "Token token=kboOp02UCu_ZiQoOlstuKTXpWHi0n-wntcWBfVEH",
                            "X-Api-Version": "20240404",
                            Accept: "application/vnd.api+json",
                        },
                    }
                )
                const result: ApiResponse = await response.json()

                // Create maps for departments and locations
                const departmentsMap = result.included
                    .filter(
                        (item): item is Department =>
                            item.attributes.name !== undefined
                    )
                    .reduce(
                        (acc, department) => {
                            acc[department.id] = department.attributes.name
                            return acc
                        },
                        {} as Record<string, string>
                    )

                const locationsMap = result.included
                    .filter(
                        (item): item is Location =>
                            item.attributes.name !== undefined
                    )
                    .reduce(
                        (acc, location) => {
                            acc[location.id] = location.attributes.name
                            return acc
                        },
                        {} as Record<string, string>
                    )

                setDepartments(departmentsMap)
                setLocations(locationsMap)
                setJobs(result.data)
                setLoading(false)
            } catch (error) {
                console.error("Error fetching jobs:", error)
                setLoading(false)
            }
        }

        fetchJobs()
    }, [])

    function formatDate(dateString: string): string {
        const date = new Date(dateString)
        if (isNaN(date.getTime())) {
            return "Invalid Date"
        }
        return date.toLocaleDateString() // Customize the date format as needed
    }

    if (loading) {
        return <div style={{ padding: 20 }}>Loading...</div>
    }

    if (!jobs.length) {
        return <div style={{ padding: 20 }}>No job listings available.</div>
    }

    return (
        <div style={{ padding: 20 }}>
            <h1>Job Listings</h1>
            <ul>
                {jobs.map((job) => (
                    <li key={job.id}>
                        <h2>{job.attributes.title}</h2>
                        <p>
                            Location(s):{" "}
                            {job.relationships.locations.data
                                .map((loc) => locations[loc.id] || "Unknown")
                                .join(", ")}
                        </p>
                        <p>
                            Department:{" "}
                            {job.relationships.department.data
                                ? departments[
                                      job.relationships.department.data.id
                                  ] || "Unknown"
                                : "Not Assigned"}
                        </p>
                        <p>
                            Posted on:{" "}
                            {formatDate(job.attributes["created-at"])}
                        </p>
                    </li>
                ))}
            </ul>
        </div>
    )
}
